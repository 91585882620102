<template>
    <div class="card-event" 
        :style="{ 
            'border-left': `7px solid ${color}`,
            'top': `${top}px`,
            'left': `${left}px`,
            'height': `${height}px`
    }">
        <div class="card-header">
            <span class="badge time-badge">{{ time }}</span>
            <span class="room-num">{{ room }}</span>
            <span class="inline-data" v-show="isInline"><img src="@/assets/tgd/reserve/reserve.svg" alt=""/></span>
        </div>
        <div class="card-content">
            <span
                v-for="aroma in aromaList"
                :key="aroma.member_name"
                class="badge name-badge"
                :style="{ 'background-color': aroma.color }"
            >
                {{ aroma.member_name }}
            </span>
        </div>
        <div class="card-footer">
            <span class="txt" v-show="customer">{{ customer }}({{reserveAmount}})</span>
            <span class="txt" v-show="customerPhone">{{ customerPhone }}</span>
        </div>
    </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import moment from "moment";
import { boolean } from 'yup/lib/locale';
export default {
    name: "CardEvent",
    props: {
        time: {
            type: String,
            required: true,
        },
        room: String,
        aromaList: {
            type: Array,
            default: [],
        },
        customer: {
            type: String,
            required: true,
        },
        customerPhone: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        row: {
            type: Number,
            required: true,
        },
        start_time: {
            type: String,
            required: true,
        },
        end_time: {
            type: String,
            required: true,
        },
        reserveAmount: {
            type: Number,
            required: true,
        },
        isInline: {
            type: Boolean,
            required: true,
        },
    },
    setup(props) {
        const left = computed(()=> props.row * 130);

        // 取得分鐘
        var minute = moment.duration(props.time).minutes();
        var top = minute % 30 / 30 * 120;

        const startTime = computed(()=> props.start_time);
        const endTime = computed(()=> props.end_time);

        function durationMinutes(start_time, end_time) {
            const format = 'HH:mm:ss';
            let startTime = start_time;
            let endTime = end_time;
            // 如果有隔天結束時間 統一減少八小時 因為只要抓時差 沒關係
            if(moment(endTime, format).isBefore(moment('08:00:00', format))) {
                startTime = moment(start_time, format).add(-8,'hours').format(format);
                endTime = moment(end_time, format).add(-8,'hours').format(format);
            }
            return moment(endTime, format).diff(moment(startTime, format), 'minutes');
        }

        const height = ref(120)
        height.value = durationMinutes(startTime.value, endTime.value) / 30 * 120;
        watch([
            startTime,
            endTime
        ],  () => {
            height.value = durationMinutes(startTime.value, endTime.value) / 30 * 120;
        })

        return {
            top,
            left,
            height
        }
    }
};
</script>

<style scoped lang="scss">
@import "~@/css/mixins";

.card-event {
    width: 160px;
    padding: 14px 14px 14px 5px;
    margin-bottom: 10px;
    margin-right: 3px;
    border: 1px solid #fff;
    border-top: none;
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.15);
    background-color: rgba(255, 255, 255, .8);
    cursor: pointer;
    position: absolute;
    z-index: 1;
}

.card-header,
.card-footer {
    padding: 0;
    border: none;
    background-color: #fff;
}

.badge {
    padding: 2px 5px;
}

.card-header {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.time-badge {
    border-radius: 0;
    font-size: 16px;
    background-color: rgba(229, 229, 229, 1);
    color: rgba(74, 74, 74, 1);
}

.room-num {
    margin-left: 10px;
    font-size: 16px;
    color: rgba(0, 124, 255, 1);
}

.card-content {
    display: flex;
    flex-wrap: wrap;
}

.name-badge {
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 0;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 5px 8px;
}

.card-footer {
    margin-top: 3px;
}

.txt {
    display: block;
    margin-bottom: 3px;
    font-size: 14px;
    color: rgba(0, 0, 0, 1);
    &:first-child {
        margin-right: 10px;
    }
}

.inline-data{
    margin-left: 10px;
    img{
        width: 30px;
        height: 30px;
    }    
}

</style>
