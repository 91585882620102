<template>
    <div class="time-row" :data-time="time">
        <div class="pointer" v-if="isShowPointer"></div>
        <div class="row-wrapper">
            <slot name="row-wrapper"></slot>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import moment from "moment";

export default {
    name: "TimeRow",
    props: {
        time: {
            type: String,
            required: true,
        },
    },
    setup({ time }) {
        const { state } = useStore();
        const reservedDate = computed(() => {
            let selectedDate = state.reservationTable.selectedDate;

            if (!selectedDate) return;

            return `${selectedDate} ${time}`;
        });
        const endDateTime = computed(() =>
            moment(reservedDate.value)
                .add(30, "m")
                .format("YYYY-MM-DD HH:mm:ss")
        );
        const isShowPointer = computed(() =>
            moment(new Date()).isBetween(
                reservedDate.value,
                endDateTime.value,
                "minute"
            )
        );

        return {
            isShowPointer,
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/css/mixins";

.time-row {
    min-height: 120px;
    padding: 0 5px;
    border-top: 1px solid rgba(162, 162, 162, 1);
    position: relative;
}

.pointer {
    @include size(100%, 3px);
    position: absolute;
    left: 0;
    top: -1.5px;
    background-color: rgba(217, 67, 67, 1);

    &::before {
        content: "";
        @include size(16px);
        border-radius: 50%;
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(217, 67, 67, 1);
    }
}

.row-wrapper {
    display: flex;
    flex-wrap: wrap;
}
</style>
