<template>
    <div class="multi-aroma">
        <div class="row">
            <div class="col-xxl-9 col-xl-8 d-xxl-block">
                <div class="main-date-toggler">
                    <button
                        type="button"
                        class="btn-toggler"
                        @click="updateDate('subtract')"
                    >
                        <i class="icon icon-arrow_left"></i>
                    </button>
                    <span class="date">
                        <span class="fw-bold">{{ year }}</span>
                        年
                        <span class="fw-bold">{{ month }}</span>
                        月
                        <span class="fw-bold">{{ date }}</span>
                        日
                        <span class="fw-bold">{{ day }}</span>
                    </span>
                    <button
                        type="button"
                        class="btn-toggler"
                        @click="updateDate('add')"
                    >
                        <i class="icon icon-arrow_right"></i>
                    </button>
                </div>

                <div class="content-panel calendar-panel">
                    <div class="time-title-wrap">
                        <div
                            v-for="time in WORK_TIME_ZONE"
                            :key="time"
                        >
                            <span>{{ time }}</span>
                        </div>
                    </div>
                    <div 
                        :style="{'overflow': 'auto', 'width': 'calc(100% - 90px)'}"
                        ref="scrollRef"
                        @mousedown="scrollMousedown"
                        @mouseleave="scrollMouseleave"
                        @mouseup="scrollMouseup"
                        @mousemove="scrollMousemove"
                    >
                        <TimeRow
                            v-for="time in WORK_TIME_ZONE"
                            :key="time"
                            :time="time"
                            :style="{
                                'min-width': listWidth
                            }"
                        >
                            <template #row-wrapper>
                                <template
                                    v-for="event in reservedList"
                                    :key="event.id"
                                >
                                    <CardEvent
                                        v-if="isBelong(time, event.time)"
                                        :time="event.time"
                                        :room="event.room_name"
                                        :aromaList="event.member"
                                        :customer="event.customer_name"
                                        :customerPhone="event.customer_phone"
                                        :color="event.color"
                                        @click="
                                            updateSelectedEventId(
                                                event.id,
                                                event.reserve_status,
                                                event.reserve_type
                                            )
                                        "
                                        :start_time="event.start_time"
                                        :end_time="event.end_time"
                                        :row="row[event.id]"
                                        :reserveAmount="event.reserve_amount"
                                        :isInline="event.reserve_type == 2 ? true : false"
                                    />
                                </template>
                            </template>
                        </TimeRow>
                    </div>
                </div>
            </div>
            <div class="col-xxl-3 col-xl-4">
                <div class="content-panel">
                    <div class="vc-calendar-wrapper">
                        <div class="vc-calendar-controller">
                            <button
                                type="button"
                                class="btn-controller"
                                @click="vCalendarRef.move(-1)"
                            >
                                <i class="icon icon-arrow_left"></i>
                            </button>
                            <span class="vc-calendar-title">
                                <span class="year">{{ calendarYear }}</span>
                                <span class="month">{{ calendarMonth }}月</span>
                            </span>
                            <button
                                type="button"
                                class="btn-controller"
                                @click="vCalendarRef.move(1)"
                            >
                                <i class="icon icon-arrow_right"></i>
                            </button>
                        </div>
                        <v-calendar
                            is-expanded
                            :attributes="vCalendarAttrs"
                            ref="vCalendarRef"
                            @update:from-page="updateVCalendarYearMonth"
                            @dayclick="handleVCalDayClick"
                        />
                    </div>

                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="輸入搜尋關鍵字"
                            v-model.trim="searchKeyword"
                            @keyup.enter="filterOnWorkAroma(1)"
                        />
                        <div class="input-group-append">
                            <button
                                class="btn"
                                type="button"
                                @click="filterOnWorkAroma(1)"
                            >
                                <img
                                    src="@/assets/tgd/reserve/magnifier.svg"
                                    alt=""
                                />
                            </button>
                        </div>
                    </div>
                    <form>
                        <BaseChoice
                            name="select_all"
                            type="checkbox"
                            customClassName="w-check-icon"
                            iconBgColor="#000000"
                            value="1"
                            @eventClick="handleSelectAll"
                        >
                            全部人員
                        </BaseChoice>
                        <div class="checkbox-wrapper">
                            <p class="subject-title">上班</p>
                            <template
                                v-for="aroma in onWorkAroma"
                                :key="aroma.member_id"
                            >
                                <BaseChoice
                                    v-show="aroma.isAppear"
                                    name="on_work_aroma"
                                    type="checkbox"
                                    customClassName="w-check-icon"
                                    :iconBgColor="aroma.color"
                                    :value="aroma.member_id"
                                >
                                    {{ aroma.member_name }}
                                    <span class="txt">
                                        (預約{{ aroma.reserve_people }})
                                    </span>
                                </BaseChoice>
                            </template>
                        </div>
                        <div class="checkbox-wrapper">
                            <p class="subject-title">休假</p>
                            <!-- 需多兩個假的不然只有單一個選項時array會變number -->
                            <BaseChoice v-show="false" name="on_holiday_aroma" />
                            <BaseChoice v-show="false" name="on_holiday_aroma" />
                            <template
                                v-for="aroma in onHolidayAroma"
                                :key="aroma.member_id"
                            >
                                <BaseChoice
                                    v-show="aroma.isAppear"
                                    name="on_holiday_aroma"
                                    type="checkbox"
                                    customClassName="w-check-icon"
                                    :iconBgColor="aroma.color"
                                    :value="aroma.member_id"
                                >
                                    {{ aroma.member_name }}
                                </BaseChoice>
                            </template>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="add-room" @click="openAddReservation">
            <div class="img-wrap">
                <img src="@/assets/tgd/reserve/add-btn.svg" alt="" />
            </div>
        </div>
    </div>
    <FixedOrderInfo/>
</template>

<script>
import { ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
import moment from "moment";
import { debounce } from "lodash";
import { useRoute, useRouter } from 'vue-router';

import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";
import useUpdateSelectedEventId from "@/hooks/reservation/useUpdateSelectedEventId";

import TimeRow from "@/components/time_utils/TimeRow";
import CardEvent from "@/components/time_utils/CardEvent";
import FixedOrderInfo from '@/components/inline/FixedOrderInfo';

export default {
    name: "MultiAroma",
    components: {
        TimeRow,
        CardEvent,
        FixedOrderInfo
    },
    setup() {
        const { state, getters, commit, dispatch } = useStore();
        const { openAddReservation } = useHandleLightboxOpen();
        const { updateSelectedEventId } = useUpdateSelectedEventId();
        const storeId = computed(() => state.auth.store_id);
        let vCalendarDate = {
            calendarYear: ref(""),
            calendarMonth: ref(""),
        };
        const dateInfo = {
            selectedDate: computed(() => state.reservationTable.selectedDate),
            year: computed(() => {
                let year = getters["reservationTable/getYear"];
                vCalendarDate.calendarYear.value = year;
                return year;
            }),
            month: computed(() => {
                let month = getters["reservationTable/getMonth"];
                vCalendarDate.calendarMonth.value = month;
                return month;
            }),
            date: computed(() => getters["reservationTable/getDate"]),
            day: computed(() => getters["reservationTable/getDay"]),
        };
        const workStatus = {
            onWorkAroma: computed(() => state.reservationTable.onWorkAroma),
            onHolidayAroma: computed(
                () => state.reservationTable.onHolidayAroma
            ),
        };
        const { values, setFieldValue } = useForm();
        const WORK_TIME_ZONE = state.reservationTable.WORK_TIME_ZONE;
        const reservedList = computed(() => state.reservationTable.listOfDaily);
        const isBelong = (comparedTime, time) => {
            const getCorrectDateTime = (time, date, format) => {
                const splitTime = time.split(":");

                if (Number(splitTime[0]) < 10) {
                    return moment(date + " " + time)
                        // 加了一天會讓 當天的新增預約 時間不顯示 故先移除
                        // .add(1, "d")
                        .format(format);
                }

                return moment(date + " " + time).format(format);
            };
            const selectedDate = dateInfo.selectedDate.value;
            const format = "YYYY-MM-DD HH:mm:ss";
            const comparedStartDate = getCorrectDateTime(
                comparedTime,
                selectedDate,
                format
            );
            const comparedEndDate = moment(comparedStartDate)
                .add(30, "m")
                .format(format);
            const date = moment(selectedDate + " " + time).format(format);
            const isSame = moment(date).isSame(comparedStartDate);
            const isBetween = moment(date).isBetween(
                comparedStartDate,
                comparedEndDate
            );

            if (isSame || isBetween) {
                return true;
            }

            return false;
        };

        // v-calendar
        const vCalendarRef = ref();
        let vCalendarAttrs = computed(
            () => state.reservationTable.listOfReservation
        );
        const update = debounce((year, month) => {
            dispatch("reservationTable/getMonthlyReservedList", {
                store_id: storeId.value,
                year,
                month,
            });
        }, 300);
        const updateVCalendarYearMonth = (info) => {
            const { year, month } = info;
            vCalendarDate.calendarYear.value = year;
            vCalendarDate.calendarMonth.value = month;

            update(year, month);
        };
        const handleVCalDayClick = (info) => {
            if (!info.attributes.length) return;

            const keyType = info.attributes[0].key;
            const isReserved = keyType.includes("reserved");

            commit("reservationTable/update", {
                key: "selectedDate",
                value: info.id,
            });
        };

        // 更新取得的預約表(checkbox)
        const getList = debounce((memberArr) => {
            commit("reservationTable/update", {
                key: "currentSelectedMemberIds",
                value: memberArr,
            });

            commit("reservationTable/filterListOfDaily");
        }, 550);
        const handleSelectAll = (checked) => {
            let workAromaArr = [];
            let holidayAroma = [];

            if (checked) {
                let updateWorkAromaArr = workStatus.onWorkAroma.value.map(
                    (aroma) => aroma.member_id
                );

                let updateHolidayAromaArr = workStatus.onHolidayAroma.value.map(
                    (aroma) => aroma.member_id
                );

                if ((updateWorkAromaArr.length + updateHolidayAromaArr)  === (values.on_work_aroma.length + values.on_holiday_aroma.length)) {
                    return;
                }

                workAromaArr = updateWorkAromaArr;
                holidayAroma = updateHolidayAromaArr
            }

            getList([...workAromaArr,...holidayAroma]);
            setFieldValue("on_work_aroma", workAromaArr);
            setFieldValue("on_holiday_aroma", holidayAroma);
        };
        watch(
            () => values.on_work_aroma,
            (arr) => {
                if (!workStatus.onWorkAroma.value || !arr) return;

                const max = workStatus.onWorkAroma.value.length + workStatus.onHolidayAroma.value.length;
                const now = values.on_work_aroma.length + values.on_holiday_aroma.length;
                if (max > now && values.select_all) {
                    setFieldValue("select_all", null);
                } else if ( max === now && !values.select_all) {
                    setFieldValue("select_all", "1");
                }

                getList([...(values.on_holiday_aroma || []), ...arr]);
            }
        );

        watch(
            () => values.on_holiday_aroma,
            (arr) => {
                if (!workStatus.onHolidayAroma.value || !arr) return;

                const max = workStatus.onWorkAroma.value.length + workStatus.onHolidayAroma.value.length;
                const now = values.on_work_aroma.length + values.on_holiday_aroma.length;
                if (max > now && values.select_all) {
                    setFieldValue("select_all", null);
                } else if ( max === now && !values.select_all) {
                    setFieldValue("select_all", "1");
                }

                getList([...(values.on_work_aroma || []), ...arr]);
            }
        );

        // 更新取得的預約表(篩選關鍵字)
        let searchKeyword = ref("");
        const filterOnWorkAroma = () => {
            let keyword = searchKeyword.value;

            workStatus.onWorkAroma.value.forEach((aroma) => {
                if (aroma.member_name.indexOf(keyword) > -1) {
                    aroma.isAppear = true;
                } else {
                    aroma.isAppear = false;
                }
            });

            getList([
                ...(values.on_work_aroma || []), 
                ...(values.on_holiday_aroma || [])
            ]);
        };

        // 預約表
        const updateDate = (type) => {
            const momentController = moment(dateInfo.selectedDate.value);

            if (type === "add") {
                momentController.add(1, "days");
            } else {
                momentController.subtract(1, "days");
            }

            commit("reservationTable/update", {
                key: "selectedDate",
                value: momentController.format("YYYY-MM-DD"),
            });
        };

        // 初始化
        const initRequest = debounce(async () => {
            await dispatch("reservationTable/initMutliRequest", {
                store_id: storeId.value,
                calendarYear: vCalendarDate.calendarYear.value,
                calendarMonth: vCalendarDate.calendarMonth.value,
                date: moment().format("YYYY-MM-DD"),
            });

            setFieldValue(
                "on_work_aroma",
                workStatus.onWorkAroma.value.map((aroma) => aroma.member_id)
            );
            setFieldValue(
                "on_holiday_aroma",
                workStatus.onHolidayAroma.value.map((aroma) => aroma.member_id)
            );
        }, 300);
        watch(
            dateInfo.date,
            async (newDate) => {
                if (isNaN(newDate)) return;
                initRequest();
            },
            {
                immediate: true,
            }
        );
        onMounted(() => {
            commit("reservationTable/update", {
                key: "viewStatus",
                value: "daily",
            });
            commit("reservationTable/fallback2Today");
        });

        const sortList = computed(()=> getters["reservationTable/getSortList"]);

        const row = computed(()=> sortList.value.reduce((curr, prev, index) => {
            const obj = prev.reduce((curr2, prev2) => {
                return {
                    ...curr2,
                    [prev2]: index
                }
            },{});
            return {
                ...curr,
                ...obj
            }
        },{}));

        const listWidth = computed(()=> {
            return `${sortList.value.length * 130}px`
        });

        const scrollRef = ref();
        const scroll = reactive({
            isDown: false,
            startX: 0,
            scrollLeft: 0
        })
        const scrollMousedown = (e) =>{
            scroll.isDown = true;
            scroll.startX = e.pageX - scrollRef.value.offsetLeft;
            scroll.scrollLeft = scrollRef.value.scrollLeft;
        }
        const scrollMouseleave =(e) => {
            scroll.isDown = false;
        }
        const scrollMouseup =(e) => {
            scroll.isDown = false;
        }
        const scrollMousemove = (e) => {
            if(!scroll.isDown) return;
            e.preventDefault();
            const x = e.pageX - scrollRef.value.offsetLeft;
            const walk = x - scroll.startX
            scrollRef.value.scrollLeft = scroll.scrollLeft - walk;
        };

        const route = useRoute();
        const router = useRouter();
        if(route.query && route.query.id){
            watch(reservedList, (list) => {
                if(!list.length) return;
                if(!route.query.id ) return;
                updateSelectedEventId(
                    route.query.id,
                    route.query.status
                )
                router.push({query: null})
            })
        }

        return {
            openAddReservation,
            vCalendarAttrs,
            ...dateInfo,
            ...workStatus,
            searchKeyword,
            filterOnWorkAroma,
            vCalendarRef,
            updateVCalendarYearMonth,
            ...vCalendarDate,
            handleSelectAll,
            handleVCalDayClick,
            updateDate,
            WORK_TIME_ZONE,
            reservedList,
            isBelong,
            updateSelectedEventId,
            row,
            listWidth,
            scrollRef,
            scrollMousedown,
            scrollMouseleave,
            scrollMouseup,
            scrollMousemove
        };
    },
};
</script>

<style lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.multi-aroma {
    position: relative;

    .main-date-toggler {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -58px;
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 27px;
    }

    .btn-toggler {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;
        height: 18px;
        font-size: 18px;
        padding: 0 20px;
        background-color: transparent;
        cursor: pointer;
    }

    .content-panel {
        &.calendar-panel {
            padding: 80px 40px 30px 0;
            display: flex;
        }

        &:not(.calendar-panel) {
            padding: 23px;
            position: sticky;
            top: 20px;
            right: 0;
        }
    }

    .time-title-wrap {
        > div {
            width: 90px;    
            height: 120px;
            text-align: center;
            span {
                display: inline-block;
                font-size: 16px;
                font-weight: 600;
                transform: translateY(-50%);
            }
        }
    }

    .input-group {
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #ffffff;
        border-radius: 11px;
        height: 53px;
        margin-top: 13px;
        margin-bottom: 26px;
        @include pad_pro_lg {
            left: 0;
            height: 45px;
        }
        .form-control {
            background: transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            border: none;
            border-radius: 11px 0px 0px 11px;
            padding: 0 15px;
            position: relative;
            font: normal normal bold 16px/21px Microsoft JhengHei;
            letter-spacing: 0px;
            color: #000000;
            @include hover(90%);
        }
        .input-group-append {
            background: transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            border-radius: 0 11px 11px 0;
            @include hover(130%);
            .btn {
                height: 100%;
                padding: 0 8px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .add-room {
        position: fixed;
        right: 29%;
        bottom: 40px;
        z-index: 1;
        @media (max-width: 1400px) {
            right: 35%;
        }
        @media (max-width: 1200px) {
            right: 40px;
        }
    }

    // v-calendar
    .vc-calendar-controller {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 13px;

        .btn-controller {
            height: 18px;
            border: none;
            line-height: 18px;
            font-size: 18px;
            background-color: transparent;
        }

        .vc-calendar-title {
            font-size: 18px;
            font-weight: 600;

            span {
                display: inline-block;
                padding: 0 23px;
                line-height: 18px;
            }
        }
    }

    .vc-container {
        border: none;
        box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.16);
    }

    .vc-header,
    .vc-arrows-container {
        display: none;
    }

    .vc-weeks {
        padding: 17px 13px;
    }

    .vc-weekday {
        border-bottom: 1px solid rgba(94, 94, 94, 0.3);
        font-size: 12px;
        color: rgba(153, 153, 153, 1);

        &:first-child,
        &:nth-child(7) {
            color: rgba(0, 124, 255, 1);
        }
    }

    .vc-day {
        &.is-today .vc-highlight {
            background-color: rgba(74, 74, 74, 0.3) !important;
        }

        &:not(.is-today) .vc-highlight {
            background-color: rgba(183, 127, 26, 0.3) !important;
        }

        .vc-day-content {
            font-size: 12px;
            font-weight: 500 !important;
            color: rgba(0, 0, 0, 1) !important;
        }
    }

    // 選取芳療師
    .checkbox-wrapper {
        &:not(:last-child) {
            margin-bottom: 45px;
        }
    }

    .subject-title {
        padding-bottom: 10px;
        margin-bottom: 16px;
        border-bottom: 1px solid rgba(152, 109, 70, 1);
        font-size: 20px;
        font-weight: 600;
        color: rgba(164, 100, 26, 1);
    }
}
</style>
